import React from 'react'
import { Link } from 'react-router-dom'


function Impliquer() {
    return (
        <>
        <section class="news section">
        <div class="container">
            <div class="row mt-30">
            <div class="col-lg-4 col-md-6 col-sm-8 col-10 m-auto">
                    <div class="blog-post">
                        <div class="post-thumb">
                            <a href="news-single.html">
                                <img src="images/speakers/IMG_0625.JPG" alt="post-image" class="img-fluid" />
                            </a>
                        </div>
                        <div class="post-content">
                        
                            <div class="post-title">
                            <h5>Devenir un partenaire</h5>
						<p>Nous sommes plus forts et plus efficaces avec des partenaires à nos côtés.</p>
                        	</div>
                            <a href="#section1" class="btn btn-main-md">LIRE LA SUITE</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8 col-10 m-auto">
                    <div class="blog-post">
                        <div class="post-thumb">
                            <a href="news-single.html">
                                <img src="images/speakers/IMG_0669.JPG" alt="post-image" class="img-fluid" />
                            </a>
                        </div>
                        <div class="post-content">
                        
                            <div class="post-title">
                            <h5>Prendre part à un dialogue</h5>
						<p>Faire de We, the Internet le nouveau modèle de gouvernance de l'Internet.</p>
                        	</div>
                            <a href="#section3" class="btn btn-main-md">LIRE LA SUITE</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8 col-10 m-auto">
                    <div class="blog-post">
                        <div class="post-thumb">
                            <a href="news-single.html">
                                <img src="images/speakers/IMG_0658.JPG" alt="post-image" class="img-fluid" />
                            </a>
                        </div>
                        <div class="post-content">
                        
                            <div class="post-title">
                            <h5>Contactez-nous</h5>
						    <p>Envoyez un message à l'équipe et nous nous ferons un plaisir de planifier une réunion.</p>
                        	</div>
                            <Link to="/contact" class="btn btn-main-md">Contactez-nous</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="section1" className="registration">
	<div className="container-fuild p-0">
		<div className="row">
			<div className="col-lg-12 col-md-12 p-0">
				<div className="registration-block bg-registration overlay-dark">
					<div className="block">
						<div className="title text-left">
							<h3>Devenir un partenaire </h3>
							<p>La méthode We, Internet pour façonner un avenir numérique commun est à la fois
                             pertinents et possibles. En devenant notre partenaire, vous nous aiderez
                              institutionnaliser les dialogues citoyens dans la gouvernance actuelle de l'Internet
                               framework et participer au dialogue de chiffrement.</p>
						</div>
						
					</div>
				</div>
			</div>
		</div>
        <section  className=" single-speaker">
	<div className="container">
  <div className="block">
			<div className="row">
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
						<div className="name">
							<h2>Le dialogue de cryptage</h2>
						</div>
						<div className="profession">
							
						</div>
						<div className="details">
							<p>L'un des problèmes politiques les plus controversés identifiés par

							 notre coalition de parties prenantes aujourd'hui est la politique de

							  cryptage. Aider les décideurs à trouver le bon équilibre entre la

							   protection des droits individuels et la sécurité de la société dans

							    son ensemble avec les citoyens. <br/></p>

                            <p>Nous proposons de déployer un dialogue sur le sujet en 2021 pour produire des recommandations
							 politiques présentées à l'IGF 2021 et au Web Summit 2021.</p>
							
						</div>
						<Link to="/contact" class="btn btn-main-md">Contactez-nous</Link>
					</div>
				</div>
        	<div className="col-lg-5 col-md-6 align-self-md-center">
					<div className="image-block"><img src="images/speakers/IMG_0636.JPG" alt="" className="img-fluid" /></div>
				</div>
			</div>
		</div>
		<div className="block">
			<div className="row">
      <div className="col-lg-5 col-md-6 align-self-md-center">
            <div className="image-block"><img src="images/speakers/IMG_0700.JPG" alt="" className="img-fluid" /></div>
          </div>
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
						<div className="name">
							<h2>Qu'y a-t-il pour vous ?</h2>
						</div>
						 {/* <div className="profession">
							<p>Web Developer</p>
						</div> */}
						<div className="details">
							<p>Devenez partenaire et vous ferez partie d'un écosystème d'acteurs qui façonneront l'Internet de demain, avec les citoyens. <br/></p>

<p>Vous ferez partie du processus et nous aiderez à définir les sujets d'intérêt jugés cruciaux pour les Dialogues Citoyens et Parties Prenantes.<br/></p>

<p>Les résultats des Dialogues produiront des éléments pour alimenter votre stratégie, en s'appuyant sur les points de vue uniques de citoyens du monde entier.</p>
							
						</div>
						{/*<div className="social-profiles">
							<h5>Social Profiles</h5>
							<ul className="list-inline social-list">
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-twitter"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-linkedin"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-facebook"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-skype"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-pinterest-p"></i></a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
        	
			</div>
		</div>
		<div className="block">
			<div className="row">
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
						
						<div className="profession">
							{/* <p>Web Developer</p> */}
						</div>
						<div className="details">
							<p>“Les Dialogues Citoyens fournissent des rapports de données quantitatifs et délibératifs aux niveaux local, national et international. Ils articulent les besoins et les visions des citoyens à différents niveaux et fournissent de nouvelles perspectives intelligentes. Ils apportent une valeur ajoutée à toutes les parties prenantes car ils sont basés sur les expériences de vie et les connaissances des citoyens, et donnent une compréhension plus approfondie et argumentée de l'avenir souhaitable d'Internet.”
							</p>
							
						</div>
                        <div className="name">
						<h2>Manon Potet<br/></h2>
							<p>Consultante pour We, the Internet</p>
						</div>
						{/* <div className="social-profiles">
							<h5>Social Profiles</h5>
							<ul className="list-inline social-list">
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-twitter"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-linkedin"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-facebook"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-skype"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-pinterest-p"></i></a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
        	<div className="col-lg-5 col-md-6 align-self-md-center">
					<div className="image-block"><img src="images/speakers/IMG_0626.JPG" alt="" className="img-fluid" /></div>
				</div>
			</div>
		</div>
	</div>
</section>
	</div>
</section>
    
 

<section id="section3" className="cta-ticket bg-ticket overlay-dark">
	<div className="container">
		<div className="row">
			<div className="col-md-8">
				<div className="content-block">
					 {/* <h2>Run your own dialogue {/*<span className="alternate">envisioning a platform</span> </h2>
					<p>You’re thinking of launching your own We, the Internet Citizens’ Dialogue?
                     It’s possible! Download our toolkit–and be sure to respect our branding policy.</p>
					<a href="" className="btn btn-main-md">Buy ticket</a> */}
                    <h2>Vous accompagner dans votre futur dialogue</h2>
                    <p>Dans notre boîte à outils dédiée et simple d'utilisation, retrouvez nos quatre modules avec des conseils pas à pas,
                     ainsi que des questions clés pour lancer des discussions de groupe et des questionnaires individuels.
                     Les principaux membres de l'équipe We, l'Internet participent à l'ensemble de la boîte à outils en partageant des informations utiles
                      conseils en cours de route.<br/></p>

                    <p>Tous les outils sont fondés sur la recherche et contextualisés pour les divers
                     réalités des acteurs mondiaux, et sont adaptés à tous les formats, du online au
                     processus en face-à-face et hybrides.</p>
					 <Link className="nav-link" class="btn btn-main-md" to={{  pathname: "https://forms.gle/w8zTVjYnyWL8mEicA" }} target="_blank">Take part</Link>
					 
				</div>
				
			</div>
		</div>
	</div>
	<div className="image-block"><img src="images/speakers/amevi.jpg" alt="" className="img-fluid" /></div>
</section>
    </>
    )
}

export default Impliquer
