import React from 'react';
import { Link } from 'react-router-dom'




function Navbar() {
    return (
        <>
            <nav className="navbar main-nav border-less fixed-top navbar-expand-lg p-0 ">
  <div className="container-fluid p-0">
     
      <Link className="navbar-brand" to="/">
        <img src="images/WhatsApp Image 2021-11-23 at 09.18.28.jpeg" alt="logo" height="65px"/>
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="fa fa-bars"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav mx-auto h1">
        
        <li className="nav-item">
          <Link className="nav-link " to="/">Accueil
            
          </Link>
        </li>
        
        {/* <li className="nav-item">
          <Link className="nav-link" to="/nousconcernant">Qui sommes-nous?</Link>
        </li> */}

        
        <li className="nav-item">
          <Link className="nav-link" to="/impliquer">S'Impliquer</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/nous_concernant">Qui sommes nous?</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact</Link>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto h1">
      <li className="nav-item">
          <Link className="nav-link" to={{  pathname: "https://forms.gle/w8zTVjYnyWL8mEicA" }} target="_blank">Prendre part
            
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={{  pathname: "https://www.facebook.com/groups/4606477909407246" }} target="_blank">Forum
            
          </Link>
        </li>
        
        <li className="nav-item">
          <Link className="nav-link" to={{  pathname: "https://twitter.com/BeninWti" }} target="_blank">Annonce</Link>
        </li>
      </ul>
      </div>
  </div>
</nav>
      </>
    )
}

export default Navbar
