import React from 'react'
import { Link } from 'react-router-dom'


function Nousconcernant() {
    return (
        <div>
           <section className="page-title bg-title overlay-dark">
	<div className="container">
		<div className="row">
			<div className="col-12 text-center">
				<div className="title">
					<h3>Qui sommes nous?</h3>
				</div>
				
			</div>
		</div>
	</div>
</section>
<section class="news-hr section mb-0">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="section-title">
					<h3>Coallition nationale, <span class="alternate">Benin</span></h3>
					<h4>Chef de file, <span class="alternate">Global Youth Network</span></h4>
				</div>
			</div>
		</div>
		<div class="sponsor-title text-center">
			<h5>Partenaires nationaux</h5>
		</div>
		<div class="block text-center">
			<div className="col-lg-12 col-md-12 align-self-md-center"><img src="images/speakers/coalition_nationale.png" alt="" className="img-fluid" height='40px' /></div>
		</div>
	</div>
</section>
 
<section className="section single-speaker">
	<div className="container">
	<div class="row">
			<div class="col-12">
				<div class="section-title">
					<h3>Chef de file de la <span class="alternate">coalition mondiale</span></h3>
				</div>
			</div>
		</div>
  <div className="block">
			<div className="row">
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
                    <img src="images/speakers/MP-logo-baseline-noir.jpg" alt="" className="img-fluid" height="50px" />
						<div className="profession">
							{/* <p>Web Developer</p> */}
						</div>
						<div className="details">
							<p>

							Missions Publiques est un entrepreneur social à fort impact dont la mission est de faire entendre la voix des citoyens ordinaires dans les discussions et négociations internationales et mondiales sur les défis sociétaux à travers l'organisation de dialogues citoyens. Nous sommes implantés à Paris (France) et à Berlin (Allemagne).<br/></p>

<p>Nous impliquons les citoyens et les parties prenantes pour améliorer la gouvernance et la prise de décision. Nous travaillons aux niveaux local, national et international, en nous appuyant sur l'expertise de non-experts pour améliorer le développement des politiques publiques et des stratégies d'entreprise.
</p>
							
						</div>
                        <Link to={{  pathname: "https://missionspubliques.org" }} target="_blank" class="btn btn-main-md">VISITEZ NOTRE SITE WEB</Link><br/><br/>
                        <Link to="/contact" class="btn btn-main-md">NOUS CONTACTER</Link>
						
					</div>
				</div>
        	    <div className="col-lg-5 col-md-6 align-self-md-center">
					<div className="image-block"><img src="images/speakers/20181208_delibera_foti-128.jpg" alt="" className="img-fluid" /></div>
				</div>
			</div>
		</div>
		
		
	</div>
</section>

<section class="sponsors section bg-sponsors overlay-white">
	<div class="container">
		
		<div class="row">
			<div class="col-12">
				<div class="sponsor-title text-center">
					<h5>Partenaires mondiaux</h5>
				</div>
				<div class="block text-center">
					<ul class="list-inline sponsors-list">
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/02.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners2.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners3.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners4.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners5.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners6.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners8.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners9.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners10.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/strat-partners11.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
					</ul>
				</div>
				
				<div class="sponsor-title text-center">
					<h5>Partenaires de coopération</h5>
				</div>
				<div class="block text-center">
					<ul class="list-inline sponsors-list">
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/coop-partners.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/coop-partners3.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
						<li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/coop-partners4.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
                        <li class="list-inline-item">
							<div class="image-block text-center">
								<a href="#">
									<img src="images/sponsors/logos.png" alt="sponsors-logo" class="img-fluid" />
								</a>
							</div>
						</li>
					</ul>
				</div>
				
			</div>
		</div>
	</div>
</section>
        </div>
    )
}

export default Nousconcernant
