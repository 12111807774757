import React from 'react'

function Footer() {
    return (
        <>
            <footer className="footer-main">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="block text-center">
            <div className="footer-logo">
              <img src="images/favicon.png" alt="logo" className="img-fluid" />
            </div>
            <ul className="social-links-footer list-inline">
              <li className="list-inline-item">
                <a href="#"><i className="fa fa-facebook"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#"><i className="fa fa-twitter"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#"><i className="fa fa-instagram"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#"><i className="fa fa-rss"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#"><i className="fa fa-vimeo"></i></a>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
    </div>
</footer>
<footer className="subfooter">
  <div className="container">
    <div className="row">
      <div className="col-md-4 align-self-center">
        <div className="copyright-text">
          <p> 2021 Tous droits réservés </p>
        </div>
      </div>
      <div className="col-md-4">
          <p>We, the internet Bénin</p>
      </div>
      <div className="col-md-4">
          <a href="#" className="to-top"><i className="fa fa-angle-up"></i></a>
      </div>
    </div>
  </div>
</footer>
</>
    )
}

export default Footer
