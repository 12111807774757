import Accueil from "./Accueil";
import Nousconcernant from "./Nousconcernant";
import Impliquer from "./Impliquer";
import Contact from "./Contact";
import Footer  from "./components/footer";
import Navbar from "./components/navbar";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';



function App() {
  return (
    <>
		<Router>
		<div className="App">
			<Navbar />
			<Route exact path='/' component={Accueil} />
			<Switch>
			<Route exact path='/nousconcernant' component={Nousconcernant} />
			<Route exact path='/impliquer' component={Impliquer} />
			<Route exact path='/nous_concernant' component={Nousconcernant} />
			<Route exact path='/contact' component={Contact} />
			</Switch>
			<Footer />
		</div>
		</Router>
    </>
  );
}

export default App;