import React, { useRef , Component } from 'react'
import emailjs from 'emailjs-com';

function Contact() {
	const form = useRef();

  	const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_579wtar', 'template_1imzlla', form.current, 'user_XzOkBqS7sHq8FWGjKCGqS')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
	  form.current.reset()
  	};
	return (
            <>
                <section className="page-title bg-title overlay-dark">
	<div className="container">
		<div className="row">
			<div className="col-12 text-center">
				<div className="title">
					<h3>Nous contacter</h3>
				</div>
				{/* <ol className="breadcrumb p-0 m-0">
				  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
				  <li className="breadcrumb-item active">Contact Us</li>
				</ol> */}
			</div>
		</div>
	</div>
</section>



<section className="section contact-form">
	<div className="container">
		<div className="row">
			<div className="col-12">
				<div className="section-title">
					<h3>Entrer en <span className="alternate">Contact</span></h3>
					<p>Notre équipe apprécierait volontiers un appel Zoom pour discuter de vos besoins, de vos espoirs ou
  préoccupations. Envoyez-nous un message rapide et nous vous contacterons pour planifier un appel !</p>
				</div>
			</div>
		</div>
		<form ref={form} onSubmit={sendEmail} className="row">
			<div className="col-md-6">
				<input type="text" className="form-control main" name="name" id="name" placeholder="Name" />
			</div>
			<div className="col-md-6">
				<input type="email" className="form-control main" name="email" id="email" placeholder="Email" />
			</div>
			<div className="col-md-12">
				<input type="text" className="form-control main" name="phone" id="phone" placeholder="Phone" />
			</div>
			<div className="col-md-12">
				<textarea name="message" id="message" className="form-control main" rows="10" placeholder="Your Message"></textarea>
			</div>
			<div className="col-12 text-center">
				<button  type="submit" className="btn btn-main-md">Envoyer le message</button>
			</div>
		</form>
	</div>
</section>
            </>
        
	)
}

export default Contact

