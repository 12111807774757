import React from 'react'

function Accueil() {
    return (
        <div>
        <section className="banner bg-banner-one overlay">
	<div className="container">
		<div className="row">
			<div className="col-lg-12">
				
				<div className="block">
					
					
					<h1>We, the Internet</h1>
				
					{/* <a href="#" className="btn btn-white-md">get ticket now</a> */}
				</div>
			</div>
		</div>
	</div>
</section>
	


    <section class="news-hr section mb-0">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="section-title">
					<h3>La gouvernance de l'Internet, <span class="alternate">avec et pour les citoyens</span></h3>
					<p>Créer en Janvier 2020, Global Youth Network se veut être un véhicule d'autonomisation et un mouvement révolutionnaire pour tous les jeunes du monde. C'est un réseau de jeunes pour affirmer leur pouvoir en nombre, en énergie et en imagination pour transformer leurs différents projets. GYN travaille pour motiver, unifier et habiliter les jeunes à être des moteurs de la transformation sociale, politique et économique. Grâce à des programmes structurés, GYN exploite des ressources et des idées pour autonomiser les leaders de demain aujourd'hui en mobilisant la conscience des jeunes et en encourageant le jeune leadership éthique.
Son plan d'action et ses initiatives se focalisent essentiellement dans l’atteinte des ODD, incluant l'éducation, l'inclusivité digital, le développement communautaire et l'entreprenariat des jeunes et des femmes. Elle travaille avec ses chapitres pays aux niveaux national, en s’appuyant sur l'expertise des jeunes leaders pour améliorer le développement local en général, mais particulièrement l'émancipation des femmes et de la jeunesse face aux enjeux de ce siècle. 
</p>
				</div>
			</div>
		</div>
		{/* <div class="row no-gutters">
			<div class="col-lg-6">
				<article class="news-post-hr">
					<div class="post-thumb">
						<a href="news-single.html">
							<img src="images/news/post-thumb-hr-one.jpg" alt="post-image" class="img-fluid" />
						</a>
					</div>
					<div class="post-contents border-top">
						<div class="post-title"><h6><a href="news-single.html">Default title here</a></h6></div>
						<div class="post-exerpts">
							<p>Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed doeiuse tempor incididunt ut
							</p>
						</div>

						<div class="date">
							<h4>20<span>May</span></h4>
						</div>
						<div class="more">
							<a href="news-single.html">Show more</a>
						</div>
					</div>
				</article>
			</div>
			<div class="col-lg-6">
				<article class="news-post-hr">
					<div class="post-thumb">
						<a href="news-single.html">
							<img src="images/news/post-thumb-hr-two.jpg" alt="post-image" class="img-fluid" />
						</a>
					</div>
					<div class="post-contents border-top">
						<div class="post-title"><h6><a href="news-single.html">Default title here</a></h6></div>
						<div class="post-exerpts">
							<p>Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed doeiuse tempor incididunt ut
							</p>
						</div>
						
						<div class="date">
							<h4>20<span>May</span></h4>
						</div>
						<div class="more">
							<a href="news-single.html">Show more</a>
						</div>
					</div>
				</article>
			</div>
			<div class="col-lg-6">
				<article class="news-post-hr">
					<div class="post-thumb">
						<a href="news-single.html">
							<img src="images/news/post-thumb-hr-three.jpg" alt="post-image" class="img-fluid" />
						</a>
					</div>
					<div class="post-contents">
						<div class="post-title"><h6><a href="news-single.html">Default title here</a></h6></div>
						<div class="post-exerpts">
							<p>Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed doeiuse tempor incididunt ut
							</p>
						</div>
						
						<div class="date">
							<h4>20<span>May</span></h4>
						</div>
						<div class="more">
							<a href="news-single.html">Show more</a>
						</div>
					</div>
				</article>
			</div>
			<div class="col-lg-6">
				<article class="news-post-hr">
					<div class="post-thumb">
						<a href="news-single.html">
							<img src="images/news/post-thumb-hr-four.jpg" alt="post-image" class="img-fluid" />
						</a>
					</div>
					<div class="post-contents">
						<div class="post-title"><h6><a href="news-single.html">Default title here</a></h6></div>
						<div class="post-exerpts">
							<p>Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed doeiuse tempor incididunt ut
							</p>
						</div>
						
						<div class="date">
							<h4>20<span>May</span></h4>
						</div>
						<div class="more">
							<a href="news-single.html">Show more</a>
						</div>
					</div>
				</article>
			</div>
		</div> */}
	</div>
</section>




{/* <section className="section speakers bg-speaker overlay-lighter">
	<div className="container">
		<div className="row">
			<div className="col-12">
				
				<div className="section-title white">
					<h3>Who <span className="alternate">Speaking?</span></h3>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusm tempor incididunt ut labore</p>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-one.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-two.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-three.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-four.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-five.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-six.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
			
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-five.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-md-4 col-sm-6">
				
				<div className="speaker-item">
					<div className="image">
						<img src="images/speakers/speaker-six.jpg" alt="speaker" className="img-fluid" />
						<div className="primary-overlay"></div>
						<div className="socials">
							<ul className="list-inline">
								<li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
								<li className="list-inline-item"><a href="#"><i className="fa fa-pinterest"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="content text-center">
						<h5><a href="single-speaker.html">Johnathan Franco</a></h5>
						<p>Project Manager</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> */}






<section className="registration">
	<div className="container-fuild p-0">
		<div className="row">
			{/* <div className="col-lg-6 col-md-12 p-0">
				<div className="service-block bg-service overlay-primary text-center">
					<div className="row no-gutters">
						<div className="col-6">
							
							<div className="service-item">
								<i className="fa fa-microphone"></i>
								<h5>8 Speakers</h5>
							</div>
						</div>
						<div className="col-6">
							
							<div className="service-item">
								<i className="fa fa-flag"></i>
								<h5>500 + Seats</h5>
							</div>
						</div>
						<div className="col-6">
							
							<div className="service-item">
								<i className="fa fa-ticket"></i>
								<h5>300 tickets</h5>
							</div>
						</div>
						<div className="col-6">
							
							<div className="service-item">
								<i className="fa fa-calendar"></i>
								<h5>3 days event</h5>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="col-lg-12 col-md-12 p-0">
				<div className="registration-block bg-registration overlay-dark">
					<div className="block">
						<div className="title text-left">
							<h3>Passer d'un dialogue <span className="alternate">à une plateforme de dialogue</span></h3>
							<p>Nous visons à institutionnaliser les processus délibératifs dans la perspective de l'édition 2025 du Sommet mondial sur la société de l'information.</p>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<section className="section single-speaker">
	<div className="container">
  <div className="block">
			<div className="row">
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
						<div className="name">
							<h2>Une première mondiale</h2>
						</div>
						<div className="profession">
							{/* <p>Web Developer</p> */}
						</div>
						<div className="details">
							<p>Jamais auparavant autant de personnes d'autant de pays différents n'avaient délibéré sur l'avenir d'Internet. Ces Dialogues s'inscrivent dans un programme ambitieux
changer la façon dont les décisions sont prises en intégrant des processus délibératifs ouverts et inclusifs.
							</p>
							
						</div>
						{/* <div className="social-profiles">
							<h5>Social Profiles</h5>
							<ul className="list-inline social-list">
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-twitter"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-linkedin"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-facebook"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-skype"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-pinterest-p"></i></a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
        	<div className="col-lg-5 col-md-6 align-self-md-center">
					<div className="image-block"><img src="images/speakers/IMG_0677.JPG" alt="" className="img-fluid" /></div>
				</div>
			</div>
		</div>
		<div className="block">
			<div className="row">
      <div className="col-lg-5 col-md-6 align-self-md-center">
            <div className="image-block"><img src="images/speakers/IMG_0781.JPG" alt="" className="img-fluid" /></div>
          </div>
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
						<div className="name">
							<h2>Qui est derrière <br/></h2>
							<h2>We, the Internet?</h2>
						</div>
						 {/* <div className="profession">
							<p>Web Developer</p>
						</div> */}
						<div className="details">
							<p>Spécialisée dans la participation citoyenne à tous les niveaux, Missions Publiques a initié ce projet unique en son genre en 2018. Depuis, l'équipe (située à Paris, Berlin et Bruxelles) a
mis en œuvre des dialogues citoyens et parties prenantes dans plus de 80 pays, grâce à une incroyable coalition de partenaires stratégiques et nationaux.
							</p>
							
						</div>
						{/*<div className="social-profiles">
							<h5>Social Profiles</h5>
							<ul className="list-inline social-list">
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-twitter"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-linkedin"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-facebook"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-skype"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-pinterest-p"></i></a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
        	
			</div>
		</div>
		<div className="block">
			<div className="row">
				<div className="col-lg-7 col-md-6 align-self-center">
					<div className="content-block">
						<div className="name">
						<h2>Préparer l'avenir <br/></h2>
							<h2>d'Internet</h2>
						</div>
						<div className="profession">
							{/* <p>Web Developer</p> */}
						</div>
						<div className="details">
							<p>Les citoyens ont exploré un avenir mieux connecté en délibérant sur les questions suivantes :
							 Comment gérer nos données ? Qui doit le gérer ? Quelles entités doivent mener la lutte contre
							  la désinformation et avec quels outils ? Comment l'IA doit-elle être gouvernée ?
							</p>
							
						</div>
						{/* <div className="social-profiles">
							<h5>Social Profiles</h5>
							<ul className="list-inline social-list">
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-twitter"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-linkedin"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-facebook"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-skype"></i></a>
								</li>
								<li className="list-inline-item">
									<a href="#"><i className="fa fa-pinterest-p"></i></a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
        	<div className="col-lg-5 col-md-6 align-self-md-center">
					<div className="image-block"><img src="images/speakers/IMG_0710.JPG" alt="" className="img-fluid" /></div>
				</div>
			</div>
		</div>
	</div>
</section>
 

<section className="cta-ticket bg-ticket overlay-dark">
	<div className="container">
		<div className="row">
			<div className="col-md-8">
				<div className="content-block">
					<h2>We, the Internet: <span className="alternate">envisage une plateforme</span></h2>
					<p>L'approche d'une discussion délibérative globale sur notre avenir numérique commun est à la fois possible
et pertinent. À partir de 2021, We, the Internet se concentrera sur le pivotement vers une plate-forme mondiale de
dialogue. L'objectif est de faire de la gouvernance délibérative un élément régulier de la gouvernance de l'Internet en
2025 et le prochain Sommet mondial sur la société de l'information afin que la gouvernance de l'Internet soit créée pour
et avec les Citoyens.</p>
					{/* <a href="" className="btn btn-main-md">Buy ticket</a> */}
				</div>
			</div>
			<div className="image-block"><img src="images/speakers/amevi.jpg" alt="" className="img-fluid" /></div>

		</div>
	</div>
	</section>


{/* <section className="sponsors section bg-sponsors overlay-white">
	<div className="container">
		<div className="row">
			<div className="col-12">
				<div className="section-title">
					<h3>Our <span className="alternate">Sponsors</span></h3>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusm tempor incididunt ut labore dolore</p>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-12">
			
				<div className="sponsor-title text-center">
					<h5>Platinum Sponsors</h5>
				</div>
				<div className="block text-center">
				
					<ul className="list-inline sponsors-list">
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/pt-spon-two.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/pt-spon-two.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/pt-spon-three.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/pt-spon-four.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
					</ul>
				</div>
			
				<div className="sponsor-title text-center">
					<h5>Gold Sponsors</h5>
				</div>
				<div className="block text-center">
				
					<ul className="list-inline sponsors-list">
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/gl-spon-one.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/gl-spon-two.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
						<li className="list-inline-item">
							<div className="image-block text-center">
								<a href="#">
									<img src="images/sponsors/gl-spon-three.png" alt="sponsors-logo" className="img-fluid" />
								</a>
							</div>
						</li>
					</ul>
				</div>
				<div className="sponsor-btn text-center">
					<a href="#" className="btn btn-main-md">Become a sponsor</a>
				</div>
			</div>
		</div>
	</div>
</section> */}




</div>
    )
}

export default Accueil
